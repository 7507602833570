import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { SubmitHandler } from 'react-hook-form';
import * as Yup from 'yup';
import styled from 'styled-components';

import { EntityType } from '../../../generated/types';
import { useJobContext } from '../Context';
import { useRouter } from '../../../hooks/useRouter';
import { Urls } from '../../urls';
import { formatUrl } from '../../../utils/url';
import { Colors } from '../../../styles/colors';
import { media } from '../../../styles/media';

import { Form } from '../../../components/atoms/Form/Form';
import { H2 } from '../../../components/atoms/Typography/Headings';
import { TextInput } from '../../../components/atoms/Form/TextInput';
import { WrapperButtons, SecondaryButton, PrimaryButton } from '../../../components/atoms/Button/Buttons';
import { PlacesAutocompleteInput } from '../../../components/atoms/Form/PlacesAutocompleteInput';
import { GoogleMap } from '../../../components/molecules/GoogleMap/GoogleMap';
import { SkeletonBlock } from '../../../components/atoms/Skeleton';
import { NavigateNextIcon } from '../../../components/atoms/Icons';
import { Contact, ContactsList } from '../../../components/molecules/Contact';
import { Modal } from '../../../components/atoms/Modal/Modal';
import { AddContactModal } from '../../../components/organisms/AddContactModal';
import { Copy } from '../../../components/atoms/Typography';

export const Company: React.FC<{}> = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { query: { userUuid, boardUuid = '', jobUuid } = {}, navigate, routerState } = useRouter();
  const { state, dispatch, title, archiveContact } = useJobContext();
  const { formValues, isLoading } = state;
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  useEffect(() => {
    const { companyLocationLatitude, companyLocationLongitude } = formValues;
    if (companyLocationLatitude && companyLocationLongitude) {
      setCoordinates({ lat: companyLocationLatitude, lng: companyLocationLongitude });
    }
  }, [isLoading]);

  if (!userUuid) {
    return null;
  }

  // We don't have a boardUuid here because we are creating a new job offer
  // if (!boardUuid) {
  //   return null;
  // }

  if (!jobUuid) {
    return null;
  }

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    dispatch({
      type: 'ADD_FORM_VALUES',
      payload: data,
    });

    dispatch({
      type: 'SET_ERRORS_TABS',
      payload: state.errorsTabs.filter((tab) => tab !== 'company'),
    });

    if (boardUuid) {
      navigate(formatUrl(`${Urls.JobUpdateHome}/${Urls.JobUpdateStep3}`, { userUuid, boardUuid, jobUuid }), {
        state: routerState,
      });
    } else {
      navigate(formatUrl(`${Urls.JobOfferUpdateHome}/${Urls.JobOfferUpdateStep3}`, { userUuid, jobUuid }), {
        state: routerState,
      });
    }
  };

  async function handleJobAddContactSubmit(values: any) {
    dispatch({
      type: 'ADD_CONTACT_VALUES',
      payload: values,
    });

    dispatch({
      type: 'CHANGE_MAX_VALID_STEP',
      payload: 2,
    });

    setIsModalVisible(false);
  }

  async function handleAddContactClick(event: any) {
    event.preventDefault();
    setIsModalVisible(true);
  }

  async function handleDeleteContactClick(position: number, uuid?: string) {
    if (uuid) {
      archiveContact({
        variables: {
          entityType: boardUuid ? EntityType.Job : EntityType.Jobpost,
          userUuid,
          boardUuid,
          jobUuid,
          contactUuid: uuid,
          isDeleted: true,
        },
      });
    }

    dispatch({
      type: 'REMOVE_CONTACT_VALUES',
      payload: position,
    });

    dispatch({
      type: 'CHANGE_MAX_VALID_STEP',
      payload: 2,
    });
  }

  function handleCancel(event: any) {
    event.preventDefault();
    setIsModalVisible(false);
  }

  const child = (
    <Form onSubmit={onSubmit} validationSchema={companyValidationSchema}>
      {isLoading ? (
        <SkeletonBlock style={{ height: '60px', marginBottom: '32px' }} />
      ) : (
        <TextInput
          name="company"
          defaultValue={formValues.company || ''}
          type="text"
          label="Company name"
          required
          onChange={() => {
            dispatch({
              type: 'CHANGE_MAX_VALID_STEP',
              payload: 2,
            });
          }}
        />
      )}

      {isLoading ? (
        <SkeletonBlock style={{ height: '60px', marginBottom: '32px' }} />
      ) : (
        <TextInput
          name="companyLinkedIn"
          defaultValue={formValues.companyLinkedIn || ''}
          type="url"
          label="Company Linkedin Profile"
          onChange={() => {
            dispatch({
              type: 'CHANGE_MAX_VALID_STEP',
              payload: 2,
            });
          }}
        />
      )}

      {isLoading ? (
        <SkeletonBlock style={{ height: '60px', marginBottom: '32px' }} />
      ) : (
        <TextInput
          name="companyWebsite"
          defaultValue={formValues.companyWebsite || ''}
          type="url"
          label="Company website"
          onChange={() => {
            dispatch({
              type: 'CHANGE_MAX_VALID_STEP',
              payload: 2,
            });
          }}
        />
      )}

      {isLoading ? (
        <SkeletonBlock style={{ height: '180px', marginBottom: '32px' }} />
      ) : (
        <TextInput
          name="companyInfo"
          multiline
          defaultValue={formValues.companyInfo || ''}
          type="text"
          label="Company information"
          minRows={5}
          onChange={() => {
            dispatch({
              type: 'CHANGE_MAX_VALID_STEP',
              payload: 2,
            });
          }}
        />
      )}

      {isLoading ? (
        <SkeletonBlock style={{ height: '60px', marginBottom: '32px' }} />
      ) : (
        <ContactsListWrapper>
          <ContactsList>
            {state.contacts.map((contact, index) => (
              <Contact
                key={index}
                fullName={contact.fullName}
                email={contact.email}
                phoneNumber={contact.phoneNumber}
                linkedin={contact.linkedin}
                position={contact.position}
                avatar={contact.avatar}
                onDeleteClick={() => handleDeleteContactClick(index, contact.uuid)}
              />
            ))}
            <Contact isSkeleton={true} onAddClick={handleAddContactClick}></Contact>
          </ContactsList>
        </ContactsListWrapper>
      )}

      {isLoading ? (
        <>
          <SkeletonBlock style={{ height: '60px', marginBottom: '32px' }} />
          <SkeletonBlock style={{ height: '180px', marginBottom: '32px' }} />
        </>
      ) : (
        <>
          <PlacesAutocompleteInput
            setCoordinates={setCoordinates}
            defaultValue={formValues.companyLocation}
            name="companyLocation"
            label="Location"
            placeholder="Where is the job offer?"
            tips={
              <Copy styleLevel={2}>
                Please provide the job location to enable us to accurately calculate commuting distance.
              </Copy>
            }
          />
          {coordinates.lat !== 0 && coordinates.lng !== 0 && (
            <GoogleMapWrapper>
              <GoogleMap center={coordinates} />
            </GoogleMapWrapper>
          )}
        </>
      )}

      <WrapperButtons>
        <SecondaryButton
          inline
          onClick={() => {
            if (routerState?.from) {
              navigate(routerState.from);
            } else {
              navigate(`/${Urls.Dashboard}`);
            }
          }}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton inline type="submit" iconRight={<NavigateNextIcon color={Colors.White} />}>
          Next
        </PrimaryButton>
      </WrapperButtons>
      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
    </Form>
  );

  return (
    <>
      <Helmet title={`${title} - Company`} />
      <H2>Company</H2>
      {child}
      <Modal isVisible={isModalVisible} setIsVisible={setIsModalVisible}>
        <AddContactModal handleCancel={handleCancel} handleSubmit={handleJobAddContactSubmit} />
      </Modal>
    </>
  );
};

export const companyValidationSchema = Yup.object().shape({
  company: Yup.string().required().max(50, 'The company name is too Long!'),
  website: Yup.string().max(100, 'The company website is too Long!'),
  companyInfo: Yup.string().max(1000, 'The company information is too Long!'),
});

type FormData = Yup.InferType<typeof companyValidationSchema>;

const GoogleMapWrapper = styled.div`
  height: 240px;
  max-width: 900px;
  margin-bottom: 32px;
`;

const ContactsListWrapper = styled.div`
  max-width: 900px;
  margin-bottom: 16px;
  ${media.from_tablet`
    margin-bottom: 32px;
  `}
`;
