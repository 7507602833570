import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { SubmitHandler } from 'react-hook-form';
import * as Yup from 'yup';

import { useJobContext } from '../Context';
import { useRouter } from '../../../hooks/useRouter';
import { Urls } from '../../urls';
import { formatUrl } from '../../../utils/url';
import { Colors } from '../../../styles/colors';

import { useCreateUploadSignedUrl } from '../../../graph/mutations/createUploadSignedUrl';

import { SelectInput, SelectItem } from '../../../components/atoms/Form/SelectInput/SelectInput';
import { Form } from '../../../components/atoms/Form/Form';
import { H2 } from '../../../components/atoms/Typography/Headings';
import { TextInput } from '../../../components/atoms/Form/TextInput';
import { WrapperButtons, SecondaryButton, PrimaryButton } from '../../../components/atoms/Button/Buttons';
import { NavigateNextIcon } from '../../../components/atoms/Icons';
import { UploadZone } from '../../../components/molecules/UploadZone';
import { File as FileComponent } from '../../../components/atoms/File';
import { Copy } from '../../../components/atoms/Typography';
import { SkeletonBlock } from '../../../components/atoms/Skeleton';
import { RichTextInput } from '../../../components/atoms/Form/RichTextInput';
import { EntityType, SeniorityLevel } from '../../../generated/types';

export const Job: React.FC<{}> = () => {
  const { query: { userUuid, boardUuid = '', jobUuid } = {}, navigate, routerState } = useRouter();
  const { state, dispatch, title, archiveFile } = useJobContext();
  const { formValues, isLoading, savedFiles, files } = state;
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [createUploadSignedUrl] = useCreateUploadSignedUrl();

  if (!userUuid) {
    return null;
  }

  // We don't have a boardUuid here because we are creating a new job offer
  // if (!boardUuid) {
  //   return null;
  // }

  if (!jobUuid) {
    return null;
  }

  const setFiles = (files: any) => {
    dispatch({
      type: 'SET_FILES',
      payload: files,
    });
  };

  async function handleFileDrop(file: File): Promise<any> {
    setIsFileUploading(true);

    // We don't have a boardUuid here because we are creating a new job offer
    if (!userUuid || !jobUuid || !file) {
      return;
    }

    try {
      // Create a deleted file in DB with a signed url. This will give us a uuid to use for the file.
      const createUploadSignedUrlResponse = await createUploadSignedUrl({
        variables: {
          entityType: boardUuid ? EntityType.Job : EntityType.Jobpost,
          userUuid,
          boardUuid,
          jobUuid,
          filename: file.name,
          mimetype: file.type,
          size: file.size,
          lastModified: file.lastModified,
        },
      });
      const { uploadSignedUrl, uuid, mimetype } = createUploadSignedUrlResponse?.data?.createUploadSignedUrl || {};
      if (!uploadSignedUrl) {
        throw new Error('uploadSignedUrl is empty');
      }
      if (!uuid) {
        throw new Error('uuid is empty');
      }

      // Upload file to S3.
      const result = await fetch(uploadSignedUrl, {
        method: 'PUT',
        body: file,
      });
      if (!result.ok) {
        setIsFileUploading(false);
        throw new Error('Could not upload file to s3');
      }

      setIsFileUploading(false);

      dispatch({
        type: 'CHANGE_MAX_VALID_STEP',
        payload: 3,
      });
      return {
        mimetype,
        uuid,
      };
    } catch (error) {
      console.error(error);
    }
  }

  async function handleDeleteFileClick(fileUuid: string) {
    try {
      if (!userUuid || !boardUuid || !jobUuid || !fileUuid) {
        throw new Error('boardUuid, jobUuid or fileUuid is empty');
      }
      await archiveFile({
        variables: {
          entityType: boardUuid ? EntityType.Job : EntityType.Jobpost,
          userUuid,
          boardUuid,
          jobUuid,
          fileUuid,
          isDeleted: true,
        },
      });
      dispatch({
        type: 'ARCHIVE_OLD_FILE',
        payload: fileUuid,
      });
      dispatch({
        type: 'CHANGE_MAX_VALID_STEP',
        payload: 3,
      });
    } catch (error) {
      console.error(error);
    }
  }

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    dispatch({
      type: 'ADD_FORM_VALUES',
      payload: data,
    });

    dispatch({
      type: 'SET_ERRORS_TABS',
      payload: state.errorsTabs.filter((tab) => tab !== 'job'),
    });

    if (boardUuid) {
      navigate(formatUrl(`${Urls.JobUpdateHome}/${Urls.JobUpdateStep4}`, { userUuid, boardUuid, jobUuid }), {
        state: routerState,
      });
    } else {
      navigate(formatUrl(`${Urls.JobOfferUpdateHome}/${Urls.JobOfferUpdateStep4}`, { userUuid, jobUuid }), {
        state: routerState,
      });
    }
  };

  const oldFilesList = savedFiles.map(({ temporaryUrl, filename, uuid: fileUuid, formattedMimetype, mimetype }) => {
    return (
      <FileComponent
        key={fileUuid}
        uuid={fileUuid}
        status="uploaded"
        mimeType={mimetype}
        formattedMimetype={formattedMimetype}
        filename={filename}
        onDeleteClick={() => handleDeleteFileClick(fileUuid)}
        onDownloadClick={() => {
          const win = window.open(temporaryUrl, '_blank');
          win?.focus();
        }}
      />
    );
  });

  const child = (
    <Form onSubmit={onSubmit} validationSchema={jobValidationSchema}>
      {isLoading ? (
        <SkeletonBlock style={{ height: '60px', marginBottom: '32px' }} />
      ) : (
        <TextInput
          name="jobTitle"
          defaultValue={formValues.jobTitle || ''}
          type="text"
          label="Job title"
          required
          onChange={() => {
            dispatch({
              type: 'CHANGE_MAX_VALID_STEP',
              payload: 3,
            });
          }}
        />
      )}

      {isLoading ? (
        <SkeletonBlock style={{ height: '60px', marginBottom: '32px' }} />
      ) : (
        <SelectInput
          defaultValue={formValues.jobSeniorityLevel}
          name="jobSeniorityLevel"
          label="Seniority"
          onChange={() => {
            dispatch({
              type: 'CHANGE_MAX_VALID_STEP',
              payload: 3,
            });
          }}
        >
          <SelectItem value="">
            <em>Select one of these</em>
          </SelectItem>
          <SelectItem value={SeniorityLevel.Entry}>Entry</SelectItem>
          <SelectItem value={SeniorityLevel.Junior}>Junior</SelectItem>
          <SelectItem value={SeniorityLevel.Mid}>Mid</SelectItem>
          <SelectItem value={SeniorityLevel.Senior}>Senior</SelectItem>
          <SelectItem value={SeniorityLevel.Lead}>Lead</SelectItem>
        </SelectInput>
      )}

      {isLoading ? (
        <SkeletonBlock style={{ height: '60px', marginBottom: '32px' }} />
      ) : (
        <RichTextInput
          name="jobDescription"
          defaultValue={formValues.jobDescription || ''}
          type="text"
          label="Job Description"
          multiline={true}
          minRows={5}
          placeholder='Job Description (e.g. "We are looking for a full stack developer")'
        />
      )}

      {isLoading ? (
        <SkeletonBlock style={{ height: '60px', marginBottom: '32px' }} />
      ) : (
        <RichTextInput
          name="jobRequirement"
          defaultValue={formValues.jobRequirement || ''}
          type="text"
          label="Job Requirement"
          multiline={true}
          minRows={5}
          placeholder='Job Requirement (e.g. "Must have 5 years experience")'
        />
      )}

      {isLoading ? (
        <SkeletonBlock style={{ height: '60px', marginBottom: '32px' }} />
      ) : (
        <TextInput
          name="jobUrl"
          defaultValue={formValues.jobUrl || ''}
          type="url"
          label="Application Url"
          onChange={() => {
            dispatch({
              type: 'CHANGE_MAX_VALID_STEP',
              payload: 3,
            });
          }}
        />
      )}

      {isLoading ? (
        <SkeletonBlock style={{ height: '260px', marginBottom: '32px' }} />
      ) : (
        <UploadZone
          copy={
            <>
              <Copy marginBottom={10}>Drag &rsquo;n&rsquo; drop any documents, or click to select files</Copy>
              <Copy marginBottom={30}>We accept MS Word, PDF & Richtext formats. </Copy>
            </>
          }
          files={files}
          setFiles={setFiles}
          onFileDrop={handleFileDrop}
        >
          {oldFilesList}
        </UploadZone>
      )}

      <WrapperButtons>
        <SecondaryButton
          disabled={isFileUploading}
          inline
          onClick={() => {
            if (routerState?.from) {
              navigate(routerState.from);
            } else {
              navigate(`/${Urls.Dashboard}`);
            }
          }}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton
          disabled={isFileUploading}
          inline
          type="submit"
          iconRight={<NavigateNextIcon color={Colors.White} />}
        >
          Next
        </PrimaryButton>
      </WrapperButtons>
      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
    </Form>
  );

  return (
    <>
      <Helmet title={`${title} - Job`} />
      <H2>Job</H2>
      {child}
    </>
  );
};

export const jobValidationSchema = Yup.object().shape({
  jobTitle: Yup.string().required().max(50, 'The job title is too Long!'),
  jobUrl: Yup.string().max(300, 'The job url is too Long!'),
});

type FormData = Yup.InferType<typeof jobValidationSchema>;
