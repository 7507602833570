import React from 'react';
import { Helmet } from 'react-helmet';
import { SubmitHandler } from 'react-hook-form';
import * as Yup from 'yup';

import { useJobContext } from '../Context';
import { useRouter } from '../../../hooks/useRouter';
import { Urls } from '../../urls';
import { formatUrl } from '../../../utils/url';
import { Colors } from '../../../styles/colors';

import { Form } from '../../../components/atoms/Form/Form';
import { H2 } from '../../../components/atoms/Typography/Headings';
import { TextInput } from '../../../components/atoms/Form/TextInput';
import { CheckboxInput } from '../../../components/atoms/Form/CheckboxInput';
import { WrapperButtons, SecondaryButton, PrimaryButton } from '../../../components/atoms/Button/Buttons';
import { NavigateNextIcon } from '../../../components/atoms/Icons';
import { AutocompleteInput } from '../../../components/atoms/Form/AutocompleteInput';
import { SkeletonBlock } from '../../../components/atoms/Skeleton';

export const Agency: React.FC<{}> = () => {
  const { query: { userUuid, boardUuid = '', jobUuid } = {}, navigate, routerState } = useRouter();
  const { state, dispatch, title } = useJobContext();
  const { formValues, isLoading } = state;

  if (!userUuid) {
    return null;
  }

  // We don't have a boardUuid here because we are creating a new job offer
  // if (!boardUuid) {
  //   return null;
  // }

  if (!jobUuid) {
    return null;
  }

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    console.log(data);
    dispatch({
      type: 'ADD_FORM_VALUES',
      payload: data,
    });

    dispatch({
      type: 'SET_ERRORS_TABS',
      payload: state.errorsTabs.filter((tab) => tab !== 'agency'),
    });

    if (boardUuid) {
      navigate(formatUrl(`${Urls.JobUpdateHome}/${Urls.JobUpdateStep2}`, { userUuid, boardUuid, jobUuid }), {
        state: routerState,
      });
    } else {
      navigate(formatUrl(`${Urls.JobOfferUpdateHome}/${Urls.JobOfferUpdateStep2}`, { userUuid, jobUuid }), {
        state: routerState,
      });
    }
  };

  const child = (
    <Form onSubmit={onSubmit} validationSchema={agencyValidationSchema}>
      {isLoading ? (
        <SkeletonBlock style={{ height: '60px', marginBottom: '32px' }} />
      ) : (
        <CheckboxInput
          isChecked={formValues.isThroughAgency}
          name="isThroughAgency"
          label="Is this position contracted through a recruitment agency?"
          onChange={() => {
            dispatch({
              type: 'CHANGE_MAX_VALID_STEP',
              payload: 1,
            });
          }}
        />
      )}

      {isLoading ? (
        <SkeletonBlock style={{ height: '60px', marginBottom: '32px' }} />
      ) : (
        <AutocompleteInput
          options={options}
          name="agencyName"
          label="Agency name"
          defaultValue={formValues.agencyName || ''}
          onChange={() => {
            dispatch({
              type: 'CHANGE_MAX_VALID_STEP',
              payload: 1,
            });
          }}
        ></AutocompleteInput>
      )}

      {isLoading ? (
        <SkeletonBlock style={{ height: '60px', marginBottom: '32px' }} />
      ) : (
        <TextInput
          name="agentName"
          defaultValue={formValues.agentName}
          type="text"
          label="Agent Name"
          placeholder='Agent Name (e.g. "John Smith")'
          onChange={() => {
            dispatch({
              type: 'CHANGE_MAX_VALID_STEP',
              payload: 1,
            });
          }}
        />
      )}

      {isLoading ? (
        <SkeletonBlock style={{ height: '60px', marginBottom: '32px' }} />
      ) : (
        <TextInput
          name="agentEmail"
          defaultValue={formValues.agentEmail || ''}
          type="email"
          label="Agent Email"
          onChange={() => {
            dispatch({
              type: 'CHANGE_MAX_VALID_STEP',
              payload: 1,
            });
          }}
        />
      )}

      {isLoading ? (
        <SkeletonBlock style={{ height: '60px', marginBottom: '32px' }} />
      ) : (
        <TextInput
          name="agentPhone"
          defaultValue={formValues.agentPhone || ''}
          type="tel"
          label="Agent Phone"
          onChange={() => {
            dispatch({
              type: 'CHANGE_MAX_VALID_STEP',
              payload: 1,
            });
          }}
        />
      )}

      {isLoading ? (
        <SkeletonBlock style={{ height: '60px', marginBottom: '32px' }} />
      ) : (
        <TextInput
          name="referralFee"
          defaultValue={formValues.referralFee || ''}
          type="text"
          label="Referral Fee"
          placeholder="Referral Fee Upon placement"
          onChange={() => {
            dispatch({
              type: 'CHANGE_MAX_VALID_STEP',
              payload: 1,
            });
          }}
        />
      )}

      <WrapperButtons>
        <SecondaryButton
          inline
          onClick={() => {
            if (routerState?.from) {
              navigate(routerState.from);
            } else {
              navigate(`/${Urls.Dashboard}`);
            }
          }}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton inline type="submit" iconRight={<NavigateNextIcon color={Colors.White} />}>
          Next
        </PrimaryButton>
      </WrapperButtons>
      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
    </Form>
  );

  return (
    <>
      <Helmet title={`${title} - Agency`} />
      <H2>Agency</H2>
      {child}
    </>
  );
};

export const agencyValidationSchema = Yup.object().shape({
  agentName: Yup.string().max(50, 'The agent name is too Long!'),
  agentEmail: Yup.string().email('The email is not valid').max(50, 'The agent email is too Long!'),
  agentPhone: Yup.string().max(32, 'The agent phone is too Long!'),
  referralFee: Yup.string().max(32, 'The referral fee is too Long!'),
});

type FormData = Yup.InferType<typeof agencyValidationSchema>;

const options = [
  'Dawson & Walsh',
  'Digitech Resourcing Ltd',
  'Evermore Global Sourcing Ltd',
  'Evolution Recruitment Solutions Ltd',
  'FE Web Team',
  'Gravitas Recruitment',
  'IT New Business',
  'Joseph Harry Limited',
  'La Fosse Associates Ltd',
  'Oliver Bernard Ltd',
  'OPEN SELECT RECRUITMENT LIMITED',
  'Premier IT',
  'ROC Search Limited',
  'Stott & May Professional Search Limited',
  'Talent Point',
  'The Tec Recruitment Group Limited',
  'Total Recruitment Specialists Ltd',
  'Totaljobs Group',
  'Understanding Recruitment Ltd',
  'VERTECH GROUP (UK) LTD',
];
