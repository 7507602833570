import styled from 'styled-components';

import { EmploymentType, RemoteOption } from '../../../generated/types';
import { media } from '../../../styles/media';
import { Colors } from '../../../styles/colors';
import { convertEnumToString } from '../../../utils/enum';

import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { DateIcon, HomeIcon, MoneyBillIcon, SuitcaseIcon } from '../../atoms/Icons';
import { Pill } from '../../atoms/Pill';

export const RateDetails = ({ ir35, duration, rate, employmentType, remoteOption }: RateDetailsProps) => {
  if (!ir35 && !duration && !rate && !employmentType && !remoteOption) {
    return null;
  }

  return (
    <ErrorBoundary message="\Molecules\RateDetails">
      <Container>
        {/* <pre>{JSON.stringify({ ir35, duration, rate, employmentType, remoteOption }, null, 2)}</pre> */}
        <ContainerIcon>
          <MoneyBillIcon size={5} />
        </ContainerIcon>
        <ContainerContent>
          {rate && (
            <LineWrapper>
              <MoneyBillIcon size={1.5} />
              Rate: {rate}
              {Boolean(ir35) && <Pill color={Colors.Contrast}>IR35</Pill>}
            </LineWrapper>
          )}

          {duration && (
            <LineWrapper>
              <DateIcon size={1.5} />
              Duration: {duration}
            </LineWrapper>
          )}
          {employmentType && (
            <LineWrapper>
              <SuitcaseIcon size={1.5} />
              {convertEnumToString('EmploymentType', employmentType)}
            </LineWrapper>
          )}
          {remoteOption && (
            <LineWrapper>
              <HomeIcon size={1.5} />
              {convertEnumToString('RemoteOption', remoteOption)}
            </LineWrapper>
          )}
        </ContainerContent>
      </Container>
    </ErrorBoundary>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  max-width: 900px;
`;

const ContainerIcon = styled.div`
  flex: 1 0 80px;
  justify-content: center;
  align-items: flex-start;

  display: none;

  ${media.from_tablet`
    display: flex;
  `}
`;

const ContainerContent = styled.div`
  flex: 5;
`;

const LineWrapper = styled.div`
  margin-bottom: 8px;
  width: 100%;
  gap: 8px;
  display: flex;
  align-items: center;
`;

type RateDetailsProps = {
  ir35: boolean;
  duration?: string;
  rate?: string;
  employmentType?: EmploymentType | null;
  remoteOption?: RemoteOption | null;
};
