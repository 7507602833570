import { createContext, useContext } from 'react';
import { JobStoreState } from '../Store';

interface JobContext {
  title: string;
  state: JobStoreState;
  dispatch: any;
  updateJob: any;
  updateJobState: any;
  archiveFile: any;
  archiveFileState: any;
  createContact: any;
  createContactState: any;
  archiveContact: any;
  archiveContactState: any;
  updateContact: any;
  updateContactState: any;
}

export const jobContext = createContext<JobContext>({} as JobContext);

export const useJobContext = () => {
  return useContext(jobContext);
};
