import { useCallback, useReducer } from 'react';
import { useLocalStorage } from '@uidotdev/usehooks';

import { EmploymentType, JobStatus, RemoteOption, SeniorityLevel } from '../../../generated/types';

export const initialState: JobStoreState = {
  isLoading: true,
  currentstep: 1,
  maxValidStep: 1,
  files: [],
  savedFiles: [],
  contacts: [],
  errorsTabs: [],
  formValues: {
    isThroughAgency: true,
    agencyName: '',
    agentName: '',
    agentEmail: '',
    agentPhone: '',
    referralFee: '',
    company: '',
    companyWebsite: '',
    companyLinkedIn: '',
    companyInfo: '',
    companyLocation: '',
    companyLocationCity: '',
    companyLocationCountry: '',
    companyLocationLatitude: 0,
    companyLocationLongitude: 0,
    companyLocationPostCode: '',
    companyLocationStreet: '',
    companyLocationUrl: '',
    jobTitle: '',
    jobSeniorityLevel: null,
    jobDescription: '',
    jobRequirement: '',
    jobUrl: '',
    employmentType: null,
    remoteOption: null,
    status: null,
    ir35: false,
    duration: '',
    rate: '',
  },
};

export function reducer(state: JobStoreState, { type, payload }: Action): JobStoreState {
  const { maxValidStep, formValues, files, savedFiles } = state;

  switch (type) {
    case 'INITIALIZE_SAVED_CONTACTS':
      return {
        ...state,
        contacts: [...payload],
      };

    case 'ADD_CONTACT_VALUES':
      return {
        ...state,
        contacts: [...state.contacts, payload],
      };

    case 'REMOVE_CONTACT_VALUES':
      return {
        ...state,
        contacts: state.contacts.filter((_contact, index) => {
          return index !== payload;
        }),
      };

    case 'INITIALIZE_FORM_VALUES':
      return {
        ...state,
        formValues: { ...payload },
        isLoading: false,
      };

    case 'ADD_FORM_VALUES':
      return {
        ...state,
        formValues: { ...formValues, ...payload },
      };

    case 'INITIALIZE_SAVED_FILES':
      return {
        ...state,
        savedFiles: [...payload],
      };

    case 'SET_FILES': {
      return {
        ...state,
        files: payload,
      };
    }

    case 'REMOVE_FILE':
      return {
        ...state,
        files: files.filter(({ uuid }) => {
          return uuid !== payload;
        }),
      };

    case 'CHANGE_STEP':
      return {
        ...state,
        maxValidStep: payload > maxValidStep ? payload : maxValidStep,
        currentstep: payload,
      };

    case 'ARCHIVE_OLD_FILE':
      return {
        ...state,
        savedFiles: savedFiles.filter(({ uuid }) => {
          return uuid !== payload;
        }),
      };

    case 'CHANGE_MAX_VALID_STEP':
      return {
        ...state,
        maxValidStep: payload,
      };

    case 'SET_ERRORS_TABS':
      return {
        ...state,
        errorsTabs: payload,
      };

    default:
      return state;
  }
}

export const usePersistReducer = (LOCAL_STORAGE_KEY: string, INITIAL_STATE: JobStoreState) => {
  // grab saved value from `localStorage` and
  // a function to update it. if
  // no value is retrieved, use `INITIAL_STATE`
  const [savedState, saveState] = useLocalStorage(LOCAL_STORAGE_KEY, INITIAL_STATE);

  // wrap `reducer` with a memoized function that
  // syncs the `newState` to `localStorage` before
  // returning `newState`. memoizing is important!
  const reducerLocalStorage = useCallback(
    (state: JobStoreState, action: Action) => {
      const newState = reducer(state, action);

      saveState(newState);

      return newState;
    },
    [saveState]
  );

  // use wrapped reducer and the saved value from
  // `localStorage` as params to `useReducer`.
  // this will return `[state, dispatch]`
  return useReducer(reducerLocalStorage, INITIAL_STATE);
};

export interface JobStoreState {
  isLoading: boolean;
  currentstep: number;
  maxValidStep: number;
  files: any[];
  savedFiles: any[];
  contacts: Contact[];
  errorsTabs: string[];
  formValues: {
    isThroughAgency: boolean;
    agencyName: string;
    agentName: string;
    agentEmail: string;
    agentPhone: string;
    referralFee: string;
    company: string;
    companyLinkedIn: string;
    companyWebsite: string;
    companyInfo: string;
    companyLocation: string;
    companyLocationCity: string;
    companyLocationCountry: string;
    companyLocationLatitude: number;
    companyLocationLongitude: number;
    companyLocationPostCode: string;
    companyLocationStreet: string;
    companyLocationUrl: string;
    jobTitle: string;
    jobSeniorityLevel: SeniorityLevel | null;
    jobDescription: string;
    jobRequirement: string;
    jobUrl: string;
    employmentType: EmploymentType | null;
    remoteOption: RemoteOption | null;
    duration: string;
    rate: string;
    ir35: boolean;
    status: JobStatus | null;
  };
}

type Contact = {
  fullName: 'string';
  position: 'string';
  phoneNumber: 'string';
  email: 'string';
  linkedin: 'string';
  avatar: 'string';
};

interface Action {
  type: ActionType;
  payload: any;
}

export type ActionType =
  | 'INITIALIZE_FORM_VALUES'
  | 'INITIALIZE_SAVED_FILES'
  | 'INITIALIZE_SAVED_CONTACTS'
  | 'ADD_FORM_VALUES'
  | 'ADD_CONTACT_VALUES'
  | 'REMOVE_CONTACT_VALUES'
  | 'SET_FILES'
  | 'REMOVE_FILE'
  | 'CHANGE_STEP'
  | 'ARCHIVE_OLD_FILE'
  | 'CHANGE_MAX_VALID_STEP'
  | 'SET_ERRORS_TABS';
