import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import * as Yup from 'yup';

import { Urls } from '../../urls';
import { formatUrl } from '../../../utils/url';
import { EmploymentType, JobStatus, RemoteOption } from '../../../generated/types';

import { useJobContext } from '../Context';
import { useRouter } from '../../../hooks/useRouter';
import { useUserContext } from '../../../contexts/UserContext';

import { Form } from '../../../components/atoms/Form/Form';
import { H2 } from '../../../components/atoms/Typography/Headings';
import { TextInput } from '../../../components/atoms/Form/TextInput';
import { WrapperButtons, SecondaryButton, PrimaryButton } from '../../../components/atoms/Button/Buttons';
import { NavigateNextIcon } from '../../../components/atoms/Icons';
import { CheckboxInput } from '../../../components/atoms/Form/CheckboxInput';
import { AutocompleteInput } from '../../../components/atoms/Form/AutocompleteInput';
import { SelectInput, SelectItem } from '../../../components/atoms/Form/SelectInput/SelectInput';
import { Colors } from '../../../styles/colors';

export const Rate: React.FC<{}> = () => {
  const { query: { userUuid, boardUuid = '', jobUuid } = {}, navigate, routerState } = useRouter();
  const { state, dispatch } = useJobContext();
  const { formValues } = state;
  const user = useUserContext();

  if (!userUuid) {
    return null;
  }

  // We don't have a boardUuid here because we are creating a new job offer
  // if (!boardUuid) {
  //   return null;
  // }

  if (!jobUuid) {
    return null;
  }

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    dispatch({
      type: 'ADD_FORM_VALUES',
      payload: data,
    });

    dispatch({
      type: 'SET_ERRORS_TABS',
      payload: state.errorsTabs.filter((tab) => tab !== 'rate'),
    });

    if (boardUuid) {
      navigate(formatUrl(`${Urls.JobUpdateHome}/${Urls.JobUpdateStep5}`, { userUuid, boardUuid, jobUuid }), {
        state: routerState,
      });
    } else {
      navigate(formatUrl(`${Urls.JobOfferUpdateHome}/${Urls.JobOfferUpdateStep5}`, { userUuid, jobUuid }), {
        state: routerState,
      });
    }
  };

  const isJob = !!(boardUuid && userUuid === user?.uuid); // Versus job offer
  const validation = isJob ? rateJobValidationSchema : rateJobPostValidationSchema;

  const child = (
    <Form onSubmit={onSubmit} validationSchema={validation}>
      {/* <pre>{JSON.stringify({ validation }, null, 2)}</pre> */}
      <CheckboxInput
        isChecked={formValues.ir35}
        name="ir35"
        label="Is the contract wiithing IR35?"
        onChange={() => {
          dispatch({
            type: 'CHANGE_MAX_VALID_STEP',
            payload: 4,
          });
        }}
      />
      <AutocompleteInput
        options={options}
        name="duration"
        label="Duration"
        defaultValue={formValues.duration || ''}
      ></AutocompleteInput>
      <TextInput
        name="rate"
        defaultValue={formValues.rate || ''}
        type="text"
        label="Rate"
        onChange={() => {
          dispatch({
            type: 'CHANGE_MAX_VALID_STEP',
            payload: 4,
          });
        }}
      />
      <SelectInput
        defaultValue={formValues.employmentType}
        name="employmentType"
        label="Employment Type"
        onChange={() => {
          dispatch({
            type: 'CHANGE_MAX_VALID_STEP',
            payload: 4,
          });
        }}
      >
        <SelectItem value="">
          <em>None of these</em>
        </SelectItem>
        <SelectItem value={EmploymentType.Contract}>Contract</SelectItem>
        <SelectItem value={EmploymentType.Internship}>Internship</SelectItem>
        <SelectItem value={EmploymentType.PermanentFullTime}>Permanent FullTime</SelectItem>
        <SelectItem value={EmploymentType.PermanentPartTime}>Permanent PartTime</SelectItem>
        <SelectItem value={EmploymentType.Temporary}>Temporary</SelectItem>
      </SelectInput>
      <SelectInput
        defaultValue={formValues.remoteOption}
        name="remoteOption"
        label="Remote Option"
        onChange={() => {
          dispatch({
            type: 'CHANGE_MAX_VALID_STEP',
            payload: 4,
          });
        }}
      >
        <SelectItem value="">
          <em>None of these</em>
        </SelectItem>
        <SelectItem value={RemoteOption.Flexible}>Flexible</SelectItem>
        <SelectItem value={RemoteOption.FullyRemote}>Fully Remote</SelectItem>
        <SelectItem value={RemoteOption.NoRemote}>No Remote</SelectItem>
        <SelectItem value={RemoteOption.OneDay}>One day on site</SelectItem>
        <SelectItem value={RemoteOption.TwoDays}>Two days on site</SelectItem>
        <SelectItem value={RemoteOption.ThreeDays}>Three days on site</SelectItem>
        <SelectItem value={RemoteOption.FourDays}>Four days on site</SelectItem>
      </SelectInput>
      {boardUuid && userUuid === user?.uuid && formValues.status !== JobStatus.Closed && (
        <SelectInput
          defaultValue={formValues.status}
          name="status"
          label="Job Status"
          onChange={() => {
            dispatch({
              type: 'CHANGE_MAX_VALID_STEP',
              payload: 4,
            });
          }}
        >
          <SelectItem value={JobStatus.Wishlist}>Wishlist</SelectItem>
          <SelectItem value={JobStatus.Applied}>Applied</SelectItem>
          <SelectItem value={JobStatus.Interview}>Interview</SelectItem>
          <SelectItem value={JobStatus.Offer}>Offer</SelectItem>
          <SelectItem value={JobStatus.Accepted}>Accepted</SelectItem>
          <SelectItem value={JobStatus.Closed}>Closed</SelectItem>
        </SelectInput>
      )}

      <WrapperButtons>
        <SecondaryButton
          inline
          onClick={() => {
            if (routerState?.from) {
              navigate(routerState.from);
            } else {
              navigate(`/${Urls.Dashboard}`);
            }
          }}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton inline type="submit" iconRight={<NavigateNextIcon color={Colors.White} />}>
          Next
        </PrimaryButton>
      </WrapperButtons>
      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
    </Form>
  );

  return (
    <>
      <H2>Rate</H2>
      {child}
    </>
  );
};

const options = ['3 Months', '6 Months', '9 Months', '12 Months', 'Open-Ended'];

export const rateJobValidationSchema = Yup.object().shape({
  status: Yup.string().required('Please select a Job Status').notOneOf(['SCRAPPED'], 'Please select a Job Status'),
});
export const rateJobPostValidationSchema = Yup.object().shape({});

type FormData = Yup.InferType<typeof rateValidationSchema>;
