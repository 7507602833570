import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { media } from '../../../styles/media';

import { PaperCollapse } from '../PaperCollapse';
import { LocationIcon, OfficeBuildingIcon, ExternalLinkIcon, LinkedinIcon } from '../../atoms/Icons';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';

export const CompanyDetails = ({
  company,
  companyWebsite,
  companyLinkedIn,
  companyInfo,
  companyLocation,
  companyLocationCity,
  companyLocationCountry,
  companyLocationLatitude,
  companyLocationLongitude,
  companyLocationPostCode,
  companyLocationStreet,
  companyLocationUrl,
}: CompanyDetailsProps) => {
  return (
    <ErrorBoundary message="\Molecules\CompanyDetails">
      {/* <pre>{JSON.stringify({ company, companyWebsite, companyInfo, companyLinkedIn, companyLocation }, null, 2)}</pre> */}
      <Container>
        <ContainerIcon>
          <OfficeBuildingIcon size={5} />
        </ContainerIcon>
        <ContainerContent>
          <Title>
            {company}
            {Boolean(companyLinkedIn) && (
              <LocationUrl target="_blank" to={companyLinkedIn}>
                <LinkedinIcon size={1} />
              </LocationUrl>
            )}
            {Boolean(companyWebsite) && (
              <LocationUrl target="_blank" to={companyWebsite}>
                <ExternalLinkIcon size={1} />
              </LocationUrl>
            )}
          </Title>
          <LineWrapper>
            {companyLocation && <LocationIcon size={1.5} />}
            {companyLocation}
            {Boolean(companyLocationUrl) && (
              <LocationUrl target="_blank" to={companyLocationUrl}>
                <ExternalLinkIcon size={1} />
              </LocationUrl>
            )}
          </LineWrapper>
          <PaperCollapse title="Company Information">{companyInfo}</PaperCollapse>
        </ContainerContent>
      </Container>
    </ErrorBoundary>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  max-width: 900px;
`;

const ContainerIcon = styled.div`
  flex: 1 0 80px;
  justify-content: center;
  align-items: flex-start;

  display: none;

  ${media.from_tablet`
    display: flex;
  `}
`;

const ContainerContent = styled.div`
  flex: 5;
`;

const LineWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 8px;
`;

const Title = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 24px;
  margin-bottom: 8px;
  gap: 8px;
`;

const LocationUrl = styled(Link)`
  transform: translateY(-8px);
`;

type CompanyDetailsProps = {
  company: string;
  companyWebsite: string;
  companyLinkedIn: string;
  companyInfo: string;
  companyLocation: string;
  companyLocationCity: string;
  companyLocationCountry: string;
  companyLocationLatitude: number;
  companyLocationLongitude: number;
  companyLocationPostCode: string;
  companyLocationStreet: string;
  companyLocationUrl: string;
};
