import styled from 'styled-components';

import { ExternalLinkIcon, StarIcon, SuitcaseIcon } from '../../atoms/Icons';
import { media } from '../../../styles/media';
import { Link } from 'react-router-dom';
import { PaperCollapse } from '../PaperCollapse';
import { SeniorityLevel } from '../../../generated/types';
import { convertEnumToString } from '../../../utils/enum';

export const JobDetails = ({
  jobTitle,
  jobDescription,
  jobRequirement,
  jobUrl,
  jobSeniorityLevel,
}: JobDetailsProps) => {
  return (
    <Container>
      <ContainerIcon>
        <SuitcaseIcon size={5} />
      </ContainerIcon>
      <ContainerContent>
        <Title>
          {jobTitle}
          {Boolean(jobUrl) && (
            <LocationUrl target="_blank" to={jobUrl}>
              <ExternalLinkIcon size={1} />
            </LocationUrl>
          )}
        </Title>
        {jobSeniorityLevel && (
          <LineWrapper>
            <StarIcon size={1.5} />
            {convertEnumToString('SeniorityLevel', jobSeniorityLevel)}
          </LineWrapper>
        )}
        <LineWrapper>
          <PaperCollapse title="Job Description">
            {jobDescription ? jobDescription : 'No description available'}
          </PaperCollapse>
        </LineWrapper>
        <LineWrapper>
          <PaperCollapse title="Job Requirements">
            {jobRequirement ? jobRequirement : 'No requirements available'}
          </PaperCollapse>
        </LineWrapper>
      </ContainerContent>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  max-width: 900px;
`;

const ContainerIcon = styled.div`
  flex: 1 0 80px;
  justify-content: center;
  align-items: flex-start;

  display: none;

  ${media.from_tablet`
    display: flex;
  `}
`;

const ContainerContent = styled.div`
  flex: 5;
`;

const LineWrapper = styled.div`
  margin-bottom: 8px;
  display: flex;
  gap: 8px;
  width: 100%;
`;

const Title = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 24px;
  margin-bottom: 8px;
`;

const LocationUrl = styled(Link)`
  transform: translateY(-8px);
`;

type JobDetailsProps = {
  jobSeniorityLevel?: SeniorityLevel | null;
  jobTitle?: string;
  jobDescription?: string;
  jobRequirement?: string;
  jobUrl: string;
};
