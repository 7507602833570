import styled from 'styled-components';

import { MailIcon, PhoneIcon, MoneyBillIcon, PeopleFullIcon } from '../../atoms/Icons';
import { media } from '../../../styles/media';
import { Colors } from '../../../styles/colors';

export const AgencyDetails = ({
  isThroughAgency,
  agencyName,
  agentName,
  agentEmail,
  agentPhone,
  referralFee,
}: AgencyDetailsProps) => {
  if (!agencyName && !agentName && !agentEmail && !agentPhone && !referralFee) {
    return null;
  }
  return (
    <Container>
      <ContainerIcon>
        <PeopleFullIcon size={5} />
      </ContainerIcon>
      <ContainerContent>
        <Title>{agencyName}</Title>
        {isThroughAgency && <ThroughAgency>The contract is through an agency</ThroughAgency>}
        <LineWrapper>{agentName}</LineWrapper>
        {agentEmail && (
          <LineWrapper>
            <MailIcon size={1.5} />
            {agentEmail}
          </LineWrapper>
        )}
        {agentPhone && (
          <LineWrapper>
            <PhoneIcon size={1.5} />
            {agentPhone}
          </LineWrapper>
        )}
        {referralFee && (
          <LineWrapper>
            <MoneyBillIcon size={1.5} />
            {referralFee} on successfull referral
          </LineWrapper>
        )}
      </ContainerContent>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  max-width: 900px;
`;

const ContainerIcon = styled.div`
  flex: 1 0 80px;
  justify-content: center;
  align-items: flex-start;

  display: none;

  ${media.from_tablet`
    display: flex;
  `}
`;

const ContainerContent = styled.div`
  flex: 5;
`;

const LineWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 24px;
  margin-bottom: 8px;
`;

const ThroughAgency = styled.div`
  font-size: 0.8rem;
  margin-bottom: 8px;
  margin-bottom: 8px;
  color: ${Colors.GreyLightest};
`;

type AgencyDetailsProps = {
  isThroughAgency: boolean;
  agencyName?: string;
  agentEmail?: string;
  agentName?: string;
  agentPhone?: string;
  referralFee?: string;
};
